import React, { useEffect, useState } from "react";
import laptop from "../../../../assets/03.png";
import "./styles.css";
import { Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import BASEURL from "../../../../BaseUrl";

const Card2 = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchCases = () => {
      axios
        .get(`${BASEURL}/api/cases/getAllCases`)
        .then((res) => {
          console.log(res);
          setData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchCases();
  }, []);
  return (
    <div
      style={{
        backgroundColor: "white",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2 style={{ textAlign: "center" }}>What makes us unique</h2>

            <p
              style={{
                textAlign: "center",
                maxWidth: "800px",
                margin: "0 auto",
              }}
            >
              Pakistan’s # 1 portal introducing on demand feature for Court
              Precedents and Legal citations encompassing all tax and fiscal
              laws.
            </p>
          </Grid>
          <Grid mt={5} item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div>
                  <img className="card2-img" src={laptop} alt="img" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {/* <div
                  // className="side-menu"
                  style={{
                    backgroundColor: "#9E742C",
                    color: "white",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "45px",
                  }}
                >
                  <h4>Case Laws</h4>
                </div> */}
                <div
                  className="side-menu"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Optional for smooth scrolling
                    });
                    setTimeout(() => {
                      window.alert("Please Login");
                    }, 1000);
                  }}
                  style={{ backgroundColor: "#F5EFFF", cursor: "pointer" }}
                >
                  <ArrowForwardIosIcon style={{ fontSize: "18px" }} />
                  {data[0]?.principleOfCaseLaws?.length > 70
                    ? `${data[0].principleOfCaseLaws.slice(0, 75)}...`
                    : data[0]?.principleOfCaseLaws}
                </div>
                <div
                  className="side-menu"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Optional for smooth scrolling
                    });
                    setTimeout(() => {
                      window.alert("Please Login");
                    }, 1000);
                  }}
                  style={{ backgroundColor: "white", cursor: "pointer" }}
                >
                  <ArrowForwardIosIcon style={{ fontSize: "18px" }} />{" "}
                  {data[1]?.principleOfCaseLaws?.length > 70
                    ? `${data[1].principleOfCaseLaws.slice(0, 75)}...`
                    : data[1]?.principleOfCaseLaws}
                </div>
                <div
                  className="side-menu"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Optional for smooth scrolling
                    });
                    setTimeout(() => {
                      window.alert("Please Login");
                    }, 1000);
                  }}
                  style={{ backgroundColor: "#F5EFFF", cursor: "pointer" }}
                >
                  <ArrowForwardIosIcon style={{ fontSize: "18px" }} />{" "}
                  {data[2]?.principleOfCaseLaws?.length > 70
                    ? `${data[2].principleOfCaseLaws.slice(0, 75)}...`
                    : data[2]?.principleOfCaseLaws}
                </div>
                <div
                  className="side-menu"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Optional for smooth scrolling
                    });
                    setTimeout(() => {
                      window.alert("Please Login");
                    }, 1000);
                  }}
                  style={{ backgroundColor: "white", cursor: "pointer" }}
                >
                  <ArrowForwardIosIcon style={{ fontSize: "18px" }} />{" "}
                  {data[3]?.principleOfCaseLaws?.length > 70
                    ? `${data[3].principleOfCaseLaws.slice(0, 75)}...`
                    : data[3]?.principleOfCaseLaws}
                </div>
                <div
                  className="side-menu"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Optional for smooth scrolling
                    });
                    setTimeout(() => {
                      window.alert("Please Login");
                    }, 1000);
                  }}
                  style={{ backgroundColor: "#F5EFFF", cursor: "pointer" }}
                >
                  <ArrowForwardIosIcon style={{ fontSize: "18px" }} />{" "}
                  {data[4]?.principleOfCaseLaws?.length > 70
                    ? `${data[4].principleOfCaseLaws.slice(0, 75)}...`
                    : data[4]?.principleOfCaseLaws}
                </div>
                <div
                  className="side-menu"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Optional for smooth scrolling
                    });
                    setTimeout(() => {
                      window.alert("Please Login");
                    }, 1000);
                  }}
                  style={{ backgroundColor: "white", cursor: "pointer" }}
                >
                  <ArrowForwardIosIcon style={{ fontSize: "18px" }} />{" "}
                  {data[5]?.principleOfCaseLaws?.length > 70
                    ? `${data[5]?.principleOfCaseLaws.slice(0, 75)}...`
                    : data[5]?.principleOfCaseLaws}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="card2-cont">
        <div>
          <div className="child-cont"></div>
        </div>
      </div>
    </div>
  );
};

export default Card2;
